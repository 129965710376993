if (module.hot) {
  module.hot.accept();
}

class AdSlider {
  constructor(options = {}) {
    this.stepsCount = 0;
    this._currentStep = 0;
    this._currentStepPrevValue = 0;
    this.parentNode = undefined;
    this.selector = options.rootElementSelector;

    this.progressBarSelector =
      (options && options.progressBarSelector) || undefined;

    this.currentStepContainerSelector =
      (options && options.currentStepContainerSelector) || undefined;

    this.stepSelector = (options && options.stepSelector) || undefined;
    this.prevButtonSelector =
      (options && options.prevButtonSelector) || undefined;
    this.nextButtonSelector =
      (options && options.nextButtonSelector) || undefined;

    this.delay = 4000;
    this.fastSwitching = false;

    this._init();
  }

  get currentStepPrevValue() {
    return this._currentStepPrevValue;
  }

  get currentStep() {
    return this._currentStep;
  }

  set currentStep(value) {
    this._currentStepPrevValue = this._currentStep;
    this._currentStep = value;
    this._initTimer();
  }

  _init() {
    this.parentNode = document.querySelector(this.selector);

    if (this.parentNode) {
      this.parentNode.style.setProperty("--delay", `${this.delay / 1000}s`);

      if (this.parentNode === undefined) {
        console.error("error");
        return;
      }

      if (this.currentStepContainerSelector !== undefined) {
        this.currentStepContainer = this.parentNode.querySelector(
          this.currentStepContainerSelector
        );
      }

      this.progressBarNode = this.parentNode.querySelector(
        this.progressBarSelector
      );
      this.descriptionNode = this.parentNode.querySelector(
        ".stories__descriptions"
      );
      this.imagesNode = this.parentNode.querySelector(".stories__images");

      this.progressBarDasheNodes = this.progressBarNode.querySelectorAll(
        ".stories-progress-bar__dash"
      );
      this.descriptionNodes = this.descriptionNode.querySelectorAll(
        ".stories__description"
      );
      this.imageContainerNodes = this.imagesNode.querySelectorAll(
        ".stories__image-container"
      );

      this.stepsCount = this.progressBarDasheNodes.length;

      this._initTimer();
      this._initEvents();
      this.update();
    }
  }

  _initEvents() {
    this.progressBarNode.addEventListener(`click`, (event) => {
      if (this.progressBarNode === event.srcElement) {
        return;
      }

      let itemIndex = [].indexOf.call(
        this.progressBarNode.children,
        event.srcElement
      );
      this.currentStep = itemIndex;
      this.update();
    });

    if (this.prevButtonSelector !== undefined) {
      const prevButton = this.parentNode.querySelector(this.prevButtonSelector);

      if (prevButton) {
        prevButton.addEventListener(`click`, () => {
          this.prevStep();
          this.update();
        });
      }
    }

    if (this.nextButtonSelector !== undefined) {
      const nextButton = this.parentNode.querySelector(this.nextButtonSelector);

      if (nextButton) {
        nextButton.addEventListener(`click`, () => {
          this.nextStep();
          this.update();
        });
      }
    }
  }

  _initTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(async () => {
      await this.nextStep();
      this._initTimer();
    }, this.delay);
  }

  async update() {
    if (this.currentStepContainer !== undefined) {
      [].forEach.call(this.currentStepContainer.children, (step) =>
        step.classList.remove(`${this.stepSelector.slice(1)}--active`)
      );

      this.currentStepContainer.children[this.currentStep].classList.add(
        `${this.stepSelector.slice(1)}--active`
      );
    }

    this.descriptionNodes.forEach((descriptionNode) =>
      descriptionNode.classList.remove("stories__description--active")
    );
    this.imageContainerNodes.forEach((descriptionNode) =>
      descriptionNode.classList.remove("stories__image-container--active")
    );

    this.descriptionNodes[this.currentStep].classList.add(
      "stories__description--active"
    );
    this.imageContainerNodes[this.currentStep].classList.add(
      "stories__image-container--active"
    );

    if (this._isFastSwitching()) {
      await this._updateDashesFast();
    } else {
      await this._updateDashes();
    }

    this.fastSwitching = false;
  }

  _isFastSwitching() {
    if (this.fastSwitching) {
      return true;
    }

    if (this.currentStep === 0) {
      return true;
    }

    return false;
  }

  async _updateDashesFast() {
    this.parentNode.style.setProperty("--delay", `0s`);
    this.progressBarDasheNodes.forEach((progressBarDasheNode) =>
      progressBarDasheNode.classList.remove(
        "stories-progress-bar__dash--active"
      )
    );
    this.progressBarDasheNodes[this.currentStep].classList.add(
      "stories-progress-bar__dash--active"
    );
  }

  _updateDashes() {
    this.progressBarDasheNodes.forEach((progressBarDasheNode) =>
      progressBarDasheNode.classList.remove(
        "stories-progress-bar__dash--active"
      )
    );
    this.progressBarDasheNodes[this.currentStep].classList.add(
      "stories-progress-bar__dash--active"
    );
  }

  async nextStep() {
    if (this.currentStep === this.stepsCount - 1) {
      this.currentStep = 0;
    } else {
      this.currentStep++;
    }

    await this.update();
  }

  async prevStep() {
    if (this.currentStep === 0) {
      this.currentStep = this.stepsCount - 1;
    } else {
      this.currentStep--;
    }

    await this.update();
  }
}

window.application = {};

const slider = new AdSlider({
  rootElementSelector: `.features-information-found__stories`,
  progressBarSelector: `.stories-progress-bar`,
});

const qrCodeButton = document.querySelector(`.introducing__qr-button`);
qrCodeButton.addEventListener('click', (event) =>
  document
    .querySelector(`.introducing__qr-code`)
    .classList.toggle(`introducing__qr-code--active`)
);

const qrCodeCapture = document.querySelector(`.capture-information__qr-button`);
qrCodeCapture.addEventListener("click", (event) =>
  document
    .querySelector(`.capture-information__qr-code`)
    .classList.toggle(`capture-information__qr-code--active`)
);

const qrCodeFooter = document.querySelector(`.page-footer__qr-button`);
qrCodeFooter.addEventListener("click", (event) =>
  document
    .querySelector(`.page-footer__qr-code`)
    .classList.toggle(`page-footer__qr-code--active`)
);

const introducingDownloadButtonApple = document.querySelector(`.introducing__download-button--apple`);
introducingDownloadButtonApple.addEventListener('click', (event) =>
  ym(84789136,'reachGoal','introducing__download-button--apple__click')
);

const captureInformationDownloadButtonApple = document.querySelector(`.capture-information__download-button--apple`);
captureInformationDownloadButtonApple.addEventListener('click', (event) =>
  ym(84789136,'reachGoal','capture-information__download-button--apple__click')
);

const pageFooterDownloadButtonApple = document.querySelector(`.page-footer__download-button--apple`);
pageFooterDownloadButtonApple.addEventListener('click', (event) =>
  ym(84789136,'reachGoal','page-footer__download-button--apple__click')
);


